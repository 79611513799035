<template>
	<div>
		<h2>Breakout rooms</h2>
		<div>
			<h4>New room</h4>
			<form id="newGroup">
				<select v-model="groupScenario">
					<option v-for="(s, index) in scenarios" :value="s" v-bind:key="index">{{ s.title }}</option>
				</select>
				<button type="button" @mousedown.prevent.stop="addGroup">Add</button>
			</form>
		</div>

		<h2>Active games</h2>

		<table>
			<tr><th>Group name</th>
				<th>Scenario</th>
				<th>Invitation link (Click to copy)</th>
				<th>Solution</th>
				<th>Connected players</th>
			</tr>
			<tr v-for="(group, index) in groups" v-bind:key="index">
				<td>Group {{ index + 1 }}</td>
				<td>
					{{ scenarios[group.scenario].title }}
				</td>
				<td>
					<a class="small" title="Click to copy to clipboard"
					@mousedown.prevent.stop="copyCode(group)">{{ groupInviteLink(group) }}</a></td>
				<td><a :href="groupLink(group)" target="_blank">Show solution</a></td>
				<td><a :class="'online ' + (group.online ? 'green' : 'red')" :title="group.online ? 'Online' : 'Offline'">&#11044;</a>
					<span v-if="group.online">{{ group.players }}</span>
				</td>
			</tr>
		</table>

		<h2>Finished games</h2>

		<table>
			<tr><th>Date</th>
				<th>Scenario</th>
				<th>Solution</th>
			</tr>
			<tr v-for="(group, index) in finishedGroups" v-bind:key="index">
				<td>{{ group.finishedDate }}</td>
				<td>
					{{ scenarios[group.scenario].title }}
				</td>
				<td><a :href="groupLink(group)" target="_blank">Show solution</a></td>
			</tr>
		</table>
	</div>

</template>
<script>
	export default {
		name: 'BreakoutRooms',
		data: function() {
			return {
				groups: [],
				finishedGroups: [],
				scenarios: [],
				groupScenario: ''
			}
		},
		created: function() {
			this.$store.dispatch("reloadProject").then(() => {
				this.$store
		        .dispatch("loadScenarios", {
		          workshop_id: this.$store.state.currentProject._id.toString(),
		        })
		        .then((scenarios) => {
		          	this.scenarios = scenarios;
					this.$store.dispatch('loadBreakoutGroups', {
						project: this.$store.state.currentProject._id.toString()
					}).then(async (groups) => {
						try {
							const rooms = await this.$game.client.getAvailableRooms("explorer_game")
						}
						catch(e) {
							console.log("Unable to connect to game server:" + e)
						}

						this.groups = groups.filter(
							(group) => !group.finished
						).map((group) => {
							if (group.room) {
								const room = rooms.find((room) => room.roomId == group.room)
								if (room) {
									group.online = true
									group.players = room.clients
								}
							}
							return group
						})
						this.finishedGroups = groups.filter(
							(group) => group.finished
						).map(group => {
							console.log(

							group.finishedDate = group.finished.toLocaleDateString("hu-HU", { // you can use undefined as first argument
								  year: "numeric",
								  month: "2-digit",
								  day: "2-digit",
								}))
							return group
						}).sort(g => g.finished).reverse()
						console.log(this.groups, this.finishedGroups)
					})
		        });
			})
		},
		methods: {
			// recreateRoom(group, index) {
			// 	this.$game.createRoom({code: group.code}).then(room => {
			// 		group.room = room.id
			// 		this.$store.dispatch("saveSolution", group).then(() => {
			// 			group.online = true;
			// 			this.groups[index] = group
			// 		});
			//     })
			// },
			copyCode(group) {
				navigator.clipboard.writeText(this.groupInviteLink(group));
			},
			groupInviteLink(group) {
				return window.location.origin + "/#/" + (group.code || group.room)
			},
			groupLink: function(group) {
				return window.location.origin + "/#/Solution/" + group._id
			},
			generateCode: async function () {
    			const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

				const generateCode = async () => {
				  var text = "";

				  for ( var i=0; i < 8; i++ ) {
				    text += possible.charAt(Math.floor(Math.random() * possible.length));
				  }

				  const solutions = await this.$store.dispatch("solutionForCodeExists", text);

				  if ( solutions.length < 1 ) {
				    return text
				  } else {
				    generateCode();
				  }
				}

				return await generateCode()
			},
			addGroup: async function() {
				const code = await this.generateCode()

				this.$store.dispatch("createSolution", {
	            	project: this.$store.state.currentProject._id.toString(),
		            code: code,
		              //group: group._id.toString(),
		              scenario: this.groupScenario._id.toString(),
		              timeline:
		                this.groupScenario.startYear ||
		                new Date().getFullYear(),
		              budget: this.groupScenario.budgetPerPhase,
		              implementations: { type: "FeatureCollection", features: [] }
		          }).then(solution => {
					this.groups.push(solution)
				});
			}
		}
	}
</script>
<style lang="scss">
	.online.green {
		color: green;
	}
	.online.red {
		color: red;
	}

</style>